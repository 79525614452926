import * as HttpService from 'services/http.service';
import {
  GET_CATEGORY_SEO_DETAILS,
  GET_CATEGORY_TREE,
  GET_PRICE_RANGE_BY_SLUG
} from 'services/url.service';

export const getCategoryTree = () =>
  HttpService.getWithoutAuth(GET_CATEGORY_TREE);

export const getCategories = async () => {
  const {
    entity: { rows: allCategories }
  } = (await getCategoryTree()) || [];
  return allCategories;
};

export const getCategoryPriceRange = (payload) =>
  HttpService.postWithoutAuth(GET_PRICE_RANGE_BY_SLUG, payload);

export const getCategorySEODetails = (categorySlug) =>
  HttpService.getWithoutAuth(GET_CATEGORY_SEO_DETAILS(categorySlug));

export const getCategoryListingPageH1Title = ({
  name: categoryName,
  heading: categoryHeading
}) => categoryHeading || categoryName;
