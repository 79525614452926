import { Fragment } from 'react';

import AccountMenu from 'components/Common/header/AccountMenu';
import SignInButton from 'components/Common/header/Header/SignInButton';
import { useUIContext } from 'components/context/uiContext/ManagedUiContext';

const LoginControls = ({
  pathName,
  isMobile,
  onHomeScreenBanner,
  onScrollNotInHomeScreen,
  ...rest
}) => {
  const { authUser } = useUIContext();
  const isAuthUser = Boolean(authUser?.id);
  return (
    <Fragment>
      {!isAuthUser && (
        <SignInButton
          {...{ pathName, onHomeScreenBanner, onScrollNotInHomeScreen }}
        />
      )}
      {!(!isAuthUser || isMobile) && (
        <AccountMenu
          {...{
            onHomeScreenBanner,
            ...rest
          }}
        />
      )}
    </Fragment>
  );
};

export default LoginControls;
