import Link from 'next/link';

import ProfileIconAndText from 'components/Common/header/Header/ProfileIconAndText';

const SignInButton = ({
  pathName,
  onHomeScreenBanner,
  onScrollNotInHomeScreen
}) => (
  <Link
    className='md:block hidden'
    href={{
      pathname: '/login',
      query: { redirectTo: pathName }
    }}
  >
    <a>
      <ProfileIconAndText
        {...{ onScrollNotInHomeScreen, onHomeScreenBanner }}
      />
    </a>
  </Link>
);

export default SignInButton;
