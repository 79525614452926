import Image from 'next/image';

import { staticMediaStoreBaseURL } from 'config/common';

const SearchIcon = ({ setShowSearchModal }) => (
  <div
    className='flex items-center px-0.5 relative basic:h-5 me-2 md:hidden '
    onClick={() => setShowSearchModal(true)}
  >
    <Image
      className='cursor-pointer'
      src={`${staticMediaStoreBaseURL}/icons/search-new-mobile.svg`}
      height={24}
      width={24}
      objectFit='cover'
      alt='Search'
    />
  </div>
);

export default SearchIcon;
