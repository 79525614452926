const SearchSuggestion = ({
  suggestions,
  selectingSuggestion,
  searchValue,
  className = ''
}) => (
  <div
    className={`absolute bg-white z-20 flex flex-col top-11 rounded-xl w-full ${className}`}
  >
    {suggestions.map((suggestion, index) => (
      <span
        className='p-2 hover:bg-platinum-300 text-black cursor-pointer text-start'
        onClick={() => selectingSuggestion(suggestion._source.name)}
        key={index}
      >
        {suggestion._source.name}
      </span>
    ))}
    <div
      onClick={() => selectingSuggestion(searchValue)}
      className='p-2 hover:bg-platinum-300 text-coral cursor-pointer text-start'
    >
      <span>See all results for “{searchValue}”</span>
    </div>
  </div>
);

export default SearchSuggestion;
