const googleLibPhoneNumber = require('google-libphonenumber');

const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();

export const PNF = googleLibPhoneNumber.PhoneNumberFormat;

export const getFormattedNumber = (
  number,
  countryCode,
  phoneUtilformatType
) => {
  const phoneUtilNumber = phoneUtil.parseAndKeepRawInput(number, countryCode);
  return phoneUtil.format(phoneUtilNumber, phoneUtilformatType);
};
