import Image from 'next/image';
import { Fragment } from 'react';

import SearchModal from 'components/Common/SearchModal';
import { staticMediaStoreBaseURL } from 'config/common';

const HomeSearchBox = ({
  setShowSearchModal,
  showSearchModal,
  searchedValue
}) => (
  <Fragment>
    <div
      className='px-2.5 relative h-4.5 basic:h-5 xxxs:h-6.5 w-4.5 basic:w-5 xxxs:w-6.5 me-2 xxxs:me-3 md:me-4 md:hidden'
      onClick={() => setShowSearchModal(true)}
    >
      <Image
        className='cursor-pointer'
        src={`${staticMediaStoreBaseURL}/icons/search_new.svg`}
        layout='fill'
        objectFit='cover'
        alt='search'
      />
    </div>
    {showSearchModal && (
      <SearchModal
        {...{
          setShowSearchModal,
          searchedValue
        }}
      />
    )}
  </Fragment>
);

export default HomeSearchBox;
