const SearchInput = ({ heading, searchedValue }) => (
  <input
    value={heading || searchedValue}
    type='text'
    autoComplete='off'
    autoFocus
    disabled
    className='ms-4 focus:bg-transparent outline-none w-full max-w-msearch md:max-w-full text-nero font-Montserrat'
  />
);

export default SearchInput;
