import Image from 'next/image';
import { useRouter } from 'next/router';

import { staticMediaStoreBaseURL } from 'config/common';
import { captureGTMEventNavBarClick } from 'lib/gtm';

const eventsName = ['Event Equipment', 'People & Services'];

const EventList = ({ category, handleNavOnHover }) => (
  <div className='flex'>
    <button className='w-full min-w-max relative focus:outline-none me-2'>
      {category.name}
    </button>
    {eventsName.includes(category.name) && (
      <div>
        <Image
          src={`${staticMediaStoreBaseURL}/icons/down-arrow.svg`}
          alt='arrow-down-icon'
          height={10}
          width={10}
          className='cursor-pointer'
          onClick={() => handleNavOnHover(category.slug)}
        />
      </div>
    )}
  </div>
);
// TODO: further code could be refactored.
const HeaderEventList = ({
  categories,
  showHeaderEventList,
  handleNavOnHover
}) => {
  const router = useRouter();
  return (
    <div className='sticky top-20 bg-ghost-white block pl-5 md:px-20'>
      <div className='flex overflow-x-scroll no-scrollbar md:overflow-hidden font-medium font-Montserrat text-10px md:text-base py-3 md:py-3.5'>
        <ul className='flex space-x-2 md:space-x-6 eventlist'>
          {categories?.map(
            (category) =>
              !category.isHidden && (
                <a
                  key={category.id}
                  href={`/categories/${category.slug}`}
                  onClick={() => {
                    captureGTMEventNavBarClick({
                      category,
                      path: router.pathname
                    });
                  }}
                >
                  <div
                    className='cursor-pointer w-max'
                    onMouseEnter={() => handleNavOnHover(category.slug)}
                  >
                    {showHeaderEventList ? (
                      <EventList {...{ category, handleNavOnHover }} />
                    ) : (
                      <li className='w-full min-w-max relative'>
                        {category.name}
                      </li>
                    )}
                  </div>
                </a>
              )
          )}
        </ul>
      </div>
    </div>
  );
};

export default HeaderEventList;
