import { useEffect, useState } from 'react';

import MegaMenuCategories from 'components/Categories/MegaMenuCategories';
import HeaderEventList from 'components/Common/header/web/HeaderEventList';
import { getCategoryTree } from 'services/categories.service';

const noMegaMenuCategories = [
  'food-and-beverages',
  'packages',
  'venues',
  'events',
  'collections'
];

const HeaderContainer = ({
  children,
  categories,
  homeScreen,
  onScrolling,
  showHeaderEventList,
  parentSlug
}) => {
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const [categoryTree, setCategoryTree] = useState([]);
  const [megaMenuCategories, setMegaMenuCategories] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(parentSlug);

  const getMegaMenuCategories = async () => {
    const {
      entity: { rows },
      status
    } = await getCategoryTree();
    status && setCategoryTree(rows);
  };

  useEffect(() => {
    getMegaMenuCategories();
  }, []);

  const handleNavOnHover = async (parentCategoryName) => {
    const shouldShowMegaMenu = !noMegaMenuCategories.some(
      (val) => parentCategoryName === val
    );
    if (parentCategoryName && shouldShowMegaMenu && categoryTree.length > 0) {
      const { childCategories } = categoryTree.find(
        ({ slug }) => slug === parentCategoryName
      );
      if (childCategories) {
        setMegaMenuCategories(childCategories);
        setShowMegaMenu(true);
        setHoveredCategory(parentCategoryName);
      }
    } else {
      setShowMegaMenu(false);
    }
  };

  const handleNavOnMouseLeave = () => {
    setShowMegaMenu(false);
  };

  const eventHeaderclass = !showHeaderEventList ? 'shadow-lg' : '';

  const homeScreenEventListClass = homeScreen
    ? 'absolute'
    : `-webkit-sticky sticky ${eventHeaderclass} bg-white`;

  // TODO: below div could be improved.

  return (
    <>
      <div
        className={`${
          onScrolling
            ? 'fixed bg-white sticky-header'
            : homeScreenEventListClass
        } top-0 w-full z-99 transition-all`}
      >
        <div
          onMouseEnter={
            showHeaderEventList ? handleNavOnMouseLeave : () => null
          }
          className='flex justify-between z-40 h-12 md:h-21 w-full items-center px-5 py-0 md:px-10 md:py-5.5'
        >
          {children}
        </div>
        {(onScrolling || showHeaderEventList) && (
          <HeaderEventList
            {...{
              handleNavOnHover,
              showHeaderEventList,
              categories
            }}
          />
        )}
      </div>
      {showHeaderEventList && showMegaMenu && (
        <MegaMenuCategories
          {...{
            parentSlug: hoveredCategory,
            categories: megaMenuCategories,
            handleNavOnMouseLeave
          }}
        />
      )}
    </>
  );
};

export default HeaderContainer;
