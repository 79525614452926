import { getUserAuth } from 'services/identity.service';

const shouldUpdateTimeZone = () => {
  const userAuth = getUserAuth() || {};
  if (!userAuth?.id) return false;

  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { timezone } = userAuth;
  const notHavingTimezone = !timezone;
  const differentTimezone = timezone !== browserTimeZone;
  return notHavingTimezone || differentTimezone;
};

export default shouldUpdateTimeZone;
