import Image from 'next/image';
import Link from 'next/link';

import { staticMediaStoreBaseURL } from 'config/common';

const HaflaLogoWeb = ({ logoUrl }) => (
  <Link
    className='cursor-pointer'
    href={'/'}
  >
    <a>
      <Image
        src={`${staticMediaStoreBaseURL}${logoUrl}`}
        alt='Hafla'
        height={32}
        width={100}
        className='inline-block hafla-header-img md:26px cursor-pointer'
      />
    </a>
  </Link>
);

export default HaflaLogoWeb;
